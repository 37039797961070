import React from 'react';
import Modal from 'react-modal';

// Modal styling
const customStyles = {
    content: {
        top: '55%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '35%', // Customize modal width
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        maxHeight: '80vh', // Set a max height for the modal
        overflowY: 'auto', // Allow vertical scrolling
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Background overlay
    }
};

Modal.setAppElement('#root'); // Bind modal to root for accessibility

const ModalWrapper = ({ isOpen, onClose, children, showModalbtn }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            style={customStyles}
            contentLabel="Time Slot Modal"
        >
            {children}
            {showModalbtn && 
            <div className='mt-[20px] w-full flex justify-center items-center gap-[20px]'>
                <button
                type="button"
                className="rounded-[10px]  w-[170px]  h-[42px] text-[#4C34AD] text-[19px] "
                onClick={onClose}
                style={{ border: '2px solid #4C34AD' }}

                >
                    Cancel
                </button>

                <button type="button"
                className=" rounded-[10px] bg-[#4C34AD] w-[170px]  h-[42px]  text-[#4C34AD] text-[19px] text-[#FFFFFF] "
                onClick={onClose} // Save schedule on click
                    >
                Update Schedule
                </button>
            </div>
            }
        </Modal>
    );
};

export default ModalWrapper;
