import React, {useState, useEffect, useContext} from 'react'
import ClassScheduler from '../../../../../Components/Scheduler/Scheduler'
import { StudentClassesContext } from '../../../../../context/StudentClassesContext'


const StudentTimeTable = ({ StudentInfo, Events, setEvents, SubmitSchedule, StudentEnrollmentSelectedTimeTable, Resources, timeTable, selectedDate, setSelectedDate, InsEvent, setPrefrredTimeTable, CoursePackage }) => {
    // Access the schedule from context
    const { studentClassesScheduleAppointments } = useContext(StudentClassesContext);


    // State to control the button's disabled state
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);

      const SaveBtn = () => {
        return (
            <button type="button"
              className="mb-[20px] rounded-[10px] bg-[#4C34AD] font-poppinssans w-[282px]  h-[48px] 2.5xl:h-[50px] text-[#4C34AD] text-[20px] 2.5xl:text-[24px] text-[#FFFFFF] px-[10px]"
              onClick={() => SubmitSchedule()} // Save schedule on click
                >
                Confirm Schedule
            </button>
        );
      };
      

    return (
        <div className='flex flex-col w-full'>
            {/* <p className="text-center text-xl w-full py-5 border-4 bg-[#F6F5F5] text-black mt-7 SemiBoldItalic">
                Set Time Table
            </p> */}
            <ClassScheduler Name={StudentInfo?.FirstName + " " + StudentInfo?.LastName}
                BottomComp={SaveBtn}
                setEvents={setEvents}
                Events={Events}
                Edit={true}
                Resources={Resources}
                timeTable={timeTable}
                StudentEnrollmentSelectedTimeTable={StudentEnrollmentSelectedTimeTable}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                InsEvent={InsEvent}
                setPrefrredTimeTable={setPrefrredTimeTable}
            />
        </div>
    )
}



export default StudentTimeTable