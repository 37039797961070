import React from 'react';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';


const ClassesIndicator = ({ text, CoursePackage, timeTable, Indicator, selectedDate, setSelectedDate}) => {
    let SpanStyle = `whitespace-nowrap text-white border-[1px] border-solid border-[#D7DAE2] h-fit
    py-3        sm:py-2         lg:py-3      xl:py-4 
    px-4        sm:px-3         lg:px-4      xl:px-5 
    px-4        md:px-5  
    text-3xs`;

      // Function to handle date selection by the user
   const handleDateChange = (date) => {
    // 'date' is passed directly from the DatePicker
    setSelectedDate(date);
  };
    return (
        // <div className='flex justify-center md:justify-end w-fit '>
        //     <span className={`bg-[#A1A3EF] ${SpanStyle} ${text} rounded-l-md`}>Driving</span>
        //     <span className={`bg-[#7F82F9] ${SpanStyle} ${text}`}>Online</span>
        //     <span className={`bg-[#5456C7] ${SpanStyle} ${text} rounded-r-md`}>In Class</span>
        // </div>
        <div className='w-[100%] flex justify-between gap-[50px]'>
            <div>
                <span className='flex justify-center md:justify-end  '>
                    <span className='flex flex-col gap-3 items-center'>
                        <button className={`${SpanStyle} ${text} bg-[#A1A3EF] rounded-l-md`}>Driving</button>
                    {timeTable === "assignSchedule" ? <span>{CoursePackage?.DrivingHours ? CoursePackage?.DrivingHours : 0} </span>  : null } 
                    </span>

                    <span className='flex flex-col gap-3 items-center'>
                        <button className={`${SpanStyle} ${text} bg-[#7F82F9]`}>
                            Online
                        </button>
                        {timeTable === "assignSchedule" ? <span>{CoursePackage?.OnlineHours ? CoursePackage?.OnlineHours : 0} </span> : null}
                    </span>
                    
                    <span className='flex flex-col gap-3 items-center'>
                        <button className={`${SpanStyle} ${text} bg-[#5456C7] rounded-r-md`}>In Class</button>
                        {timeTable === "assignSchedule" ? <span>{CoursePackage?.InClassHours ? CoursePackage?.InClassHours : 0} </span> : null}
                    </span>
                </span>
            </div>

            {timeTable === "assignSchedule" ?
            <div className='w-[800px] flex justify-center'>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                    value={selectedDate}
                    onChange={handleDateChange}
                    // onChange={(date) => {
                    //   setSelectedDate(date);
                    // }}
                    renderInput={(params) => <TextField {...params} style={{ height: '30px', width: '300px' }} />}
                />
            </LocalizationProvider>
            </div> : null}

            <div className='flex items-start'>
                {timeTable === "assignSchedule" ? 
                    <span className='flex flex-col gap-3 items-center'>
                        <button className={`${SpanStyle} ${text} bg-[#1EC1FE] rounded-l-md`}>
                            Student
                        </button>
                    </span>
                : null }
                {timeTable === "assignSchedule" ? 
                <span className='flex flex-col gap-3 items-center'>
                    <button className={`${SpanStyle} ${text} bg-[#4C34AD] rounded-r-md`}>
                        Instructor
                    </button>
                </span>
                : null }
            </div>
        </div>
    )
}

export default ClassesIndicator